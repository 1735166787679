import profilePic from '../Images/IG_logo.webp';
import '../styles/App.scss';
import FB from '../Images/Icons/SocialIcons/facebook_white.svg';
import TW from '../Images/Icons/SocialIcons/twitter_white.svg';
import LI from '../Images/Icons/SocialIcons/linkedin_white.svg';
import WS from '../Images/Icons/website_white.svg';
import Love from '../Images/Icons/heart_white.svg';
import Advice from '../Images/Icons/advice_white.svg';
import Learn from '../Images/Icons/learn_white.svg';
import Talk from '../Images/Icons/talk_white.svg';

function App() {
  return (
    <>
      <div className="link-collection__container">
        <div className="link-collection__header">
          <img src={profilePic} alt="Instagram Logo" width="150xp" />
          {/* <h1>Where would you like to go?</h1> */}
          <h3>@paladinnsas</h3>
        </div>

        <a href="https://www.justgiving.com/paladin-nsas" className="link-collection__btn"
        >
          <img className="link-collection__img" src={Love} alt="" />
            Donate to Paladin</a>

        <a className="link-collection__btn"
          href="https://paladinservice.co.uk/">
          <img className="link-collection__img" src={WS} alt="" />
          Visit our Website</a>

        <a className="link-collection__btn"
          href="https://paladinservice.co.uk/advice-for-victims/">
          <img className="link-collection__img" src={Advice} alt="" />
          Advice For Victims</a>

        <a className="link-collection__btn"
          href="https://paladinservice.co.uk/advice-for-victims/referring-cases-to-paladin/">
          <img className="link-collection__img" src={Talk} alt="" />
          Make a Referral</a>

        <a className="link-collection__btn"
          href="https://paladinservice.co.uk/accredited-training/">
          <img className="link-collection__img" src={Learn} alt="" />
          Training Courses</a>

        <a className="link-collection__btn"
          href="https://www.linkedin.com/in/paladin-nsas-a06339200/">
          <img className="link-collection__img" src={LI} alt="" />
          LinkedIn</a>

        <a className="link-collection__btn"
          href="https://twitter.com/paladinservice">
          <img className="link-collection__img" src={TW} alt="" />
          Twitter</a>

        <a className="link-collection__btn"
          href="https://www.facebook.com/PaladinNSAS">
          <img className="link-collection__img" src={FB} alt="" />
          Facebook</a>
      </div>
      <div className="link-collection__footer"><p>Site by Saskia Bobinska</p></div>
    </>
  );
}

export default App;
